import PropTypes from 'prop-types'
import React from 'react'
// Section images
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
// Icons
import ForumIcon from '@material-ui/icons/Forum'
import MapIcon from '@material-ui/icons/Map'
import WebIcon from '@material-ui/icons/Web'
import OppositeContentTimeline from './Timeline'
import CustomTimeline from './CustomTimeline'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>

          <p>
            I live to give. Whether that means providing business solutions for
            clients, creating awesome stuff with artists, or putting together
            events to bring community together - I'm up for the challenge, and
            strive for stellar results.
          </p>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/rosstonritter"
                className="icon fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a
                href="mailto:busterritterllc@gmail.com"
                className="icon fa-envelope"
              >
                <span className="label">Mail</span>
              </a>
            </li>
          </ul>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>

          <p>
            With a busy background in diverse areas such as business strategy,
            executive search, operations management, sales/marketing, and
            webdev, I seek to leverage my experience, knowledge, and network to
            provide solutions to the challenges you may be facing in business or
            life.
          </p>
          <ForumIcon />
          <h3>SMB Solutions</h3>
          <p>
            Having worked with a number of startups and established companies of
            all sizes, there's nothing we can't figure out together.
          </p>
          <MapIcon />
          <h3>Analysis + Planning</h3>
          <p>
            All good ideas are just ideas until one actually executes. However,
            efficient execution necessitates issue identification and proper
            planning. Let's get that atlas out and develop a roadmap to
            greatness.
          </p>
          <WebIcon />
          <h3>WebDev + Design</h3>
          <p>
            Navigating the ever-evolving landscape of technology and innovation
            doesn't have to be overwhelming or scary. In spite of those fears,
            let's have fun with new things.
          </p>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/rosstonritter"
                className="icon fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a
                href="mailto:busterritterllc@gmail.com"
                className="icon fa-envelope"
              >
                <span className="label">Mail</span>
              </a>
            </li>
          </ul>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>

          <p>
            Tending towards learning by immersion, Buster's background is varied
            and extensive. He thrives in fast-paced, evolving environments, and
            always seeks to apply established and newfound knowledge and
            experience. Don't let the semblance of spontaneity fool you, as
            Buster's laser focus and drive always provides.
          </p>
          <CustomTimeline />

          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/rosstonritter"
                className="icon fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a
                href="mailto:busterritterllc@gmail.com"
                className="icon fa-envelope"
              >
                <span className="label">Mail</span>
              </a>
            </li>
          </ul>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <p>
            Contact Buster to learn more about how he can help you in business
            or life, how his journey can become part of yours, and how to create
            something great.
          </p>

          <form
            name="contact"
            method="post"
            action="/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="6" />
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Clear" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/rosstonritter"
                className="icon fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a
                href="mailto:busterritterllc@gmail.com"
                className="icon fa-envelope"
              >
                <span className="label">Mail</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
