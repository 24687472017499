import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import LaptopMacIcon from '@material-ui/icons/LaptopMac'
import HotelIcon from '@material-ui/icons/Hotel'
import RepeatIcon from '@material-ui/icons/Repeat'

import SchoolIcon from '@material-ui/icons/School'
import FilterHdrIcon from '@material-ui/icons/FilterHdr'
import WorkIcon from '@material-ui/icons/Work'
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '6px 10px',
    color: 'white',
    backgroundColor: 'transparent',
    minWidth: '150px',
  },
  secondaryTail: {
    backgroundColor: theme.palette,
  },
}))

export default function CustomTimeline() {
  const classes = useStyles()

  return (
    <Timeline align="left">
      <TimelineItem>
        <TimelineOppositeContent>
          <Typography>2012</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <SchoolIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <ul>
              <Typography>
                <strong>Athens, GA</strong>
              </Typography>
              <br />
              <li>
                <Typography>BBA, Marketing (UGA)</Typography>
              </li>
              <li>
                <Typography>Business Owner</Typography>
              </li>
              <li>
                <Typography>SCUBA Instructor (Honduras)</Typography>
              </li>
            </ul>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <Typography>2013</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <WorkIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <ul>
              <Typography>
                <strong>Atlanta, GA</strong>
              </Typography>
              <br />
              <li>
                <Typography>Executive Search</Typography>
              </li>
              <li>
                <Typography>Movie set production</Typography>
              </li>
              <li>
                <Typography>Musician / DJ</Typography>
              </li>
            </ul>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <Typography>2014</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <FilterHdrIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <ul>
              <Typography>
                <strong>Telluride, CO</strong>
              </Typography>
              <br />
              <li>
                <Typography>
                  Resort Sales, Services, Operations, Data
                </Typography>
              </li>
              <li>
                <Typography>Restaurant management</Typography>
              </li>
              <li>
                <Typography>Retail accounting</Typography>
              </li>
              <li>
                <Typography>Writer / Content Contributor</Typography>
              </li>
            </ul>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <Typography>2019</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <EmojiTransportationIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <ul>
              <Typography>
                <strong>Denver, CO</strong>
              </Typography>
              <br />

              <li>
                <Typography>Rideshare industry</Typography>
              </li>
              <li>
                <Typography>Business Development</Typography>
              </li>
              <li>
                <Typography>Strategic Relations</Typography>
              </li>
              <li>
                <Typography>Partnerships / Account Management</Typography>
              </li>
            </ul>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <Typography>2020</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <LaptopMacIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <ul>
              <Typography>
                <strong>Atlanta, GA</strong>
              </Typography>
              <br />

              <li>
                <Typography>Martech startup founder</Typography>
              </li>
              <li>
                <Typography>Full-stack developer</Typography>
              </li>
              <li>
                <Typography>Strategy Consultant</Typography>
              </li>
              <li>
                <Typography>WebDev / Design</Typography>
              </li>
              <li>
                <Typography>Data analysis</Typography>
              </li>
            </ul>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  )
}
